<template>
  <b-row>
    <b-col>
      <b-card>
        EM CONTRUÇÃO
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Index',
}
</script>

<style scoped>

</style>
